import styled from "styled-components"
import { Link } from "gatsby"

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;

  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    object-position: center;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(6, 19, 46, 0.49);
    z-index: 0;
    transition: background-color 0.4s ease-in-out;
  }
`

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const Slogan = styled.div`
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 1.5px;
  opacity: 0.9;
  margin-bottom: 2rem;

  @media screen and (max-width: 620px) {
    font-size: 36px;
    line-height: 130%;
  }

  @media screen and (max-width: 568px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  @media screen and (max-height: 580px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
`

export const Subtitle = styled.div`
  color: white;
  opacity: 0.8;
  font-size: 24px;
  margin-bottom: 1rem;
  text-align: center;

  > * + * {
    margin-top: 0.3rem;
  }

  @media screen and (max-width: 620px) {
    font-size: 20px;
  }

  @media screen and (max-width: 568px) {
    display: none;
  }

  @media screen and (max-height: 580px) {
    display: none;
  }
`

export const VolumeContainer = styled.div`
  margin-bottom: 1rem;
  @media screen and (max-width: 768px), screen and (max-height: 768px) {
    display: none;
  }
`

export const LinkStyled = styled(Link)`
  display: block;
  text-align: center;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.05rem;
  background: transparent;
  text-decoration: none;
  border: 2px solid white;
  padding: 0.7rem 1.5rem;
  border-radius: 10px;

  &:hover {
    background: white;
    color: rgba(0, 0, 0, 0.8);
  }

  @media screen and (max-width: 568px) {
    font-size: 14px;
  }

  @media screen and (max-height: 580px) {
    font-size: 14px;
  }
`

export const Button = styled(Link)`
  font-size: 18px;
  font-family: "Alegreya Sans", sans-serif;
  padding: 0.7rem 1.5rem;
  background-color: rgb(246, 119, 64);
  border-radius: 10px;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;

  @media screen and (max-width: 568px) {
    font-size: 14px;
  }

  @media screen and (max-height: 580px) {
    font-size: 14px;
  }
`

export const Links = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 20vh;

  > * + * {
    margin-left: 2rem;
  }

  @media screen and (max-width: 568px) {
    flex-direction: column;
    margin-bottom: 3rem;

    > * + * {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  @media screen and (max-height: 580px) {
    margin-bottom: 1rem;
    flex-direction: row;
    > * + * {
      margin-left: 2rem;
      margin-top: 0;
    }
  }
`

export const FooterVisibility = styled.div`
  @media only screen and (max-height: 400px) {
    display: none;
  }
`
