import React from "react"

import { Button } from "./Volume.styled"
import Unmuted from "../../../static/images/shared/svg/unmuted.svg"
import Muted from "../../../static/images/shared/svg/muted.svg"

const Volume = ({ isMuted, toggleMute }) => {
  return (
    <Button onClick={toggleMute}>{isMuted ? <Muted /> : <Unmuted />}</Button>
  )
}

export default Volume
