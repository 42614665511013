import React from "react"

import Layout from "../../layouts/MainLayout/Layout"
import Seo from "../../config-components/seo"
import {
  Button,
  Content,
  FooterVisibility,
  Links,
  LinkStyled,
  Slogan,
  Subtitle,
  VideoWrapper,
  VolumeContainer,
} from "./PageIndex.styled"
import { usePageIndex } from "./hooks"
import Volume from "../../components/Volume/Volume"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import { graphql, useStaticQuery } from "gatsby"

const PageIndex = ({ location }) => {
  const query = useStaticQuery(graphql`
    query IndexQuery {
      images: allFile(
        filter: { relativeDirectory: { regex: "/images/index/" } }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const queryImg = (name) =>
    query.images.edges.filter((el) => el.node.name === name)[0].node.publicURL
  const poster = queryImg("katamaran-photo-poster")
  const background = queryImg("katamaran-photo-background")

  const {
    isMuted,
    videoRef,
    toggleMute,
    isOpened,
    clbMenuOpened,
    isVideoDisplayed,
  } = usePageIndex()
  return (
    <Layout>
      <Header page={location.pathname} toggleClb={clbMenuOpened} />
      <Seo
        title="Купить катамаран (водный велосипед) от производителя. Доставка."
        description="Купить катамаран по отличной цене - просто. Надувной катамаран с педалями, моторный катамаран и другие модели. Гарантия. Доставка."
        keywords="купить катамаран, водный велосипед, катамаран с педалями"
      />
      <VideoWrapper>
        {isVideoDisplayed ? (
          <video
            ref={videoRef}
            autoPlay={true}
            loop={true}
            muted={isMuted}
            poster={poster}
          >
            <source
              src="https://res.cloudinary.com/di8meiwx0/video/upload/v1590731578/bg_gfrlgu.mp4"
              type="video/mp4"
            />
            <source
              src="https://res.cloudinary.com/di8meiwx0/video/upload/v1590731612/bg_rqzpqu.webm"
              type="video/webm"
            />
            <source
              src="https://res.cloudinary.com/di8meiwx0/video/upload/v1590731650/bg_mrwbpd.ogv"
              type="video/ogg"
            />
          </video>
        ) : (
          <img
            src={background}
            alt="катамаран с педалями (водный велосипед) на воде"
          />
        )}
      </VideoWrapper>
      {!isOpened && (
        <Content>
          <Slogan>доставай из багажника — покоряй стихию</Slogan>
          <Subtitle>
            <h1>Катамараны Marina-m1 – компактность, доступность и комфорт.</h1>
            <p>Создаем современные модели для бизнеса, хобби, отдыха.</p>
          </Subtitle>
          <Links>
            <LinkStyled to="/catalog">в каталог</LinkStyled>
            <Button to="/contacts">заказать звонок</Button>
          </Links>
          <VolumeContainer>
            <Volume isMuted={isMuted} toggleMute={toggleMute} />
          </VolumeContainer>
        </Content>
      )}
      <FooterVisibility>
        <Footer styles={{ background: "none", paddingBottom: "2rem" }} />
      </FooterVisibility>
    </Layout>
  )
}

export default PageIndex
