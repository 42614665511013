import { useState, useRef, useEffect } from "react"

export const usePageIndex = () => {
  const [isMuted, setMuted] = useState(true)
  const [isOpened, setOpened] = useState(false)
  const [isVideoDisplayed, displayVideo] = useState(false)
  const videoRef = useRef(null)

  const toggleMute = () => setMuted(!isMuted)

  const clbMenuOpened = (isOpened) => setOpened(isOpened)

  const handleResize = () => {
    if (window.innerWidth <= 768 || window.innerHeight <= 768) {
      displayVideo(false)
    } else {
      displayVideo(true)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return {
    isMuted,
    videoRef,
    toggleMute,
    isOpened,
    clbMenuOpened,
    isVideoDisplayed,
  }
}
